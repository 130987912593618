import { Link } from "gatsby";
import React from "react";

function success() {
  return (
    <div className="suc-body">
      <div className="suc-card">
        <div className="suc-card-icon">
          <i className="checkmark">✓</i>
        </div>
        <h1>Erfolgreich</h1>
        <p>
          Wir haben Deine Bestellung erhalten
          <br /> und melden uns in Kürze per mail bei Dir!
        </p>

        <Link to="/" className="">
          <p>
            <br />
            Zurück zum Einkauf
          </p>
        </Link>
      </div>
    </div>
  );
}

export default success;
